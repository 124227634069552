import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  EncompassConfig,
  EncompassHost,
  LOANPASS_PRICE_LOCK_REQUEST_TRANSACTION_TYPE,
} from "../hooks";
import {
  EncompassErrorDetail,
  EncompassSyncLockLedgerResponse,
} from "../generated-types";
import * as T from "../generated-types";

interface LoanpassEmbedProps {
  appUrl: string;
  encompassHost: EncompassHost;
  encompassConfig: EncompassConfig;
}

type LoanpassState =
  | { state: "pending" }
  | { state: "logging-in" }
  | { state: "ready" }
  | { state: "submitting" }
  | { state: "lock-success"; isFloat: boolean }
  | {
      state: "error";
      message: string;
      details: EncompassErrorDetail[];
      dismissable: boolean;
    }
  | {
      state: "warning";
      message: string;
      details: EncompassErrorDetail[];
    };

export const LoanpassEmbed: React.FC<LoanpassEmbedProps> = (props) => {
  const { appUrl, encompassHost, encompassConfig } = props;

  const clientAccessId = encompassConfig.loanpassAuth.loanpassClientAccessId;
  const sessionToken =
    encompassConfig.loanpassAuth.loanpassSessionDetails.sessionToken;

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const [loanpassState, setLoanpassState] = useState<LoanpassState>({
    state: "pending",
  });

  const onIframeLoad = useCallback(() => {
    const contentWindow = iframeRef.current?.contentWindow;

    if (contentWindow == null) {
      return;
    }

    contentWindow.postMessage({ message: "connect" }, appUrl);
  }, [appUrl]);

  const onIframeListening = useCallback(() => {
    const contentWindow = iframeRef.current?.contentWindow;
    const overrideCreditApplicationFields = Object.entries(
      encompassConfig.creditApplicationFields,
    ).map((entry) => ({ fieldId: entry[0], value: entry[1] }));

    if (contentWindow == null) {
      console.warn("Could not get iframe content window");
      return;
    }

    switch (loanpassState.state) {
      case "pending":
        contentWindow.postMessage(
          {
            message: "log-in-with-session",
            sessionToken,
            clientAccessId,
          },
          appUrl,
        );
        setLoanpassState({ state: "logging-in" });
        break;
      case "logging-in":
        contentWindow.postMessage(
          {
            message: "set-pipeline-record-id",
            pipelineRecordId: encompassConfig.pipelineRecordId,
            overrideCreditApplicationFields,
          },
          appUrl,
        );

        contentWindow.postMessage(
          {
            message: "enable-float-requests",
          },
          appUrl,
        );
        contentWindow.postMessage(
          {
            message: "enable-vendor-context",
          },
          appUrl,
        );

        if (encompassConfig.mappingErrors.length > 0) {
          setLoanpassState({
            state: "warning",
            message: "Some fields encountered errors during mapping.",
            details: encompassConfig.mappingErrors,
          });
        } else {
          setLoanpassState({ state: "ready" });
        }

        break;
      case "ready":
        console.warn("Received 'listening' message when state was ready");
        break;
    }
  }, [
    loanpassState,
    clientAccessId,
    sessionToken,
    appUrl,
    encompassConfig.creditApplicationFields,
    encompassConfig.mappingErrors,
    encompassConfig.pipelineRecordId,
  ]);

  const onVendorSyncRequest = useCallback(
    async (message: LoanpassIFrameVendorContextRequestMessage) => {
      const contentWindow = iframeRef.current?.contentWindow;
      if (contentWindow == null) {
        console.warn("Could not get iframe content window");
        setLoanpassState({
          state: "error",
          message:
            "Failed to send loan data back to Encompass: content window not available",
          details: [],
          dismissable: false,
        });
        return;
      }

      if (loanpassState.state !== "ready") {
        const errorMessage =
          "Failed to send loan data back to Encompass: LoanPASS state is not ready";
        console.warn("Encompass update failed", { errorMessage });
        contentWindow.postMessage(
          {
            message: "vendor-context-response",
            id: message.id,
            error: errorMessage,
          },
          appUrl,
        );
        setLoanpassState({
          state: "error",
          message: errorMessage,
          details: [],
          dismissable: false,
        });
        return;
      }

      if (encompassHost.transaction == null) {
        const errorMessage =
          "Failed to send loan data back to Encompass: Encompass context is not available";
        console.warn("Encompass update failed", { errorMessage });
        contentWindow.postMessage(
          {
            message: "vendor-context-response",
            id: message.id,
            error: errorMessage,
          },
          appUrl,
        );
        setLoanpassState({
          state: "error",
          message: errorMessage,
          details: [],
          dismissable: false,
        });
        return;
      }

      setLoanpassState({ state: "submitting" });

      const newTransaction = await encompassHost.transaction.create({
        request: {
          type: LOANPASS_PRICE_LOCK_REQUEST_TRANSACTION_TYPE,
          options: {},
          resources: [],
        },
      });

      console.info("Created new transaction", { newTransaction });

      await encompassHost.transaction.set(newTransaction);

      console.info("Set transaction");

      contentWindow.postMessage(
        {
          message: "vendor-context-response",
          id: message.id,
          value: {
            vendor: "encompass",
            transactionId: newTransaction.id,
            originatingParty: encompassConfig.originatingParty,
          },
        },
        appUrl,
      );
    },
    [loanpassState, encompassHost, encompassConfig.originatingParty, appUrl],
  );

  const onLockLedgerUpdated = useCallback(
    async (message: LoanpassIFrameLockLedgerUpdatedMessage) => {
      console.info(`Created pipeline record request`, {
        message,
      });
      setLoanpassState({ state: "lock-success", isFloat: false });
    },
    [],
  );

  const onFloatRequestSubmitted = useCallback(
    async (message: LoanpassEncompassFloatRequestMessage) => {
      if (loanpassState.state !== "ready") {
        console.warn("LoanPASS state is not ready", { loanpassState });
        return;
      }

      if (encompassHost.transaction == null) {
        console.warn(
          `Failed to get Encompass host context for pipeline record update`,
        );
        return;
      }

      setLoanpassState({ state: "submitting" });

      const currentTransaction = await encompassHost.transaction.get();

      let transactionId: string;
      if (currentTransaction.id != null) {
        console.info("Got existing transaction", { currentTransaction });
        transactionId = currentTransaction.id;
      } else {
        const newTransaction = await encompassHost.transaction.create({
          request: {
            type: LOANPASS_PRICE_LOCK_REQUEST_TRANSACTION_TYPE,
            options: {},
            resources: [],
          },
        });

        console.info("Created new transaction", { newTransaction });

        await encompassHost.transaction.set(newTransaction);

        console.info("Set transaction");

        transactionId = newTransaction.id;
      }

      const response = await fetch(
        `${appUrl}/api/vendor-integrations/encompass/sync-float-request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
          body: JSON.stringify({
            transactionId,
            originatingParty: encompassConfig.originatingParty,
            pipelineRecordId: message.pipelineRecordId,
            pipelineRecordScenarioId: message.pipelineRecordScenarioId,
          }),
        },
      );

      if (response.ok) {
        const priceLockResponse: EncompassSyncLockLedgerResponse =
          (await response.json()) as unknown as EncompassSyncLockLedgerResponse;

        switch (priceLockResponse.kind) {
          case "success":
            console.info(`Created pipeline record request`, {
              loanpassPriceLockResponse: response,
            });
            setLoanpassState({ state: "lock-success", isFloat: false });
            break;
          case "timeout":
            setLoanpassState({
              state: "error",
              message: "Request timed out. Please try again.",
              details: [],
              dismissable: true,
            });
            break;
          case "error":
            setLoanpassState({
              state: "error",
              message: priceLockResponse.message,
              details: priceLockResponse.details,
              dismissable: true,
            });
        }

        // Close the Encompass embed
      } else {
        let message = `Failed to create pipeline record request`;
        console.warn(message, { loanpassPriceLockResponse: response });
        setLoanpassState({
          state: "error",
          message,
          details: [],
          dismissable: true,
        });
      }
    },
    [
      loanpassState,
      encompassHost,
      appUrl,
      sessionToken,
      encompassConfig.originatingParty,
    ],
  );

  const onIframeMessage = useCallback(
    (event: MessageEvent<LoanpassIFrameMessage>) => {
      if (event.origin !== appUrl) {
        console.info("Received message from unexpected origin", event);
        return;
      }

      console.log("Got message", event.data);

      switch (event.data.message) {
        case "listening":
          onIframeListening();
          break;
        case "lock-ledger-updated":
          onLockLedgerUpdated(event.data);
          break;
        case "lock-ledger-update-error":
          setLoanpassState({
            state: "error",
            message: "Error submitting lock request",
            details: [
              {
                key: null,
                detail: event.data.error,
              },
            ],
            dismissable: false,
          });
          break;
        case "float-request":
        case "price-lock":
          break;
        case "encompass-float-request":
          onFloatRequestSubmitted(event.data);
          break;
        case "vendor-context-request":
          onVendorSyncRequest(event.data);
      }
    },
    [
      appUrl,
      onIframeListening,
      onLockLedgerUpdated,
      onFloatRequestSubmitted,
      onVendorSyncRequest,
    ],
  );

  useEffect(() => {
    window.addEventListener("message", onIframeMessage);

    return () => {
      window.removeEventListener("message", onIframeMessage);
    };
  }, [onIframeMessage]);

  const onDismissError = useCallback(() => {
    if (loanpassState.state === "error" || loanpassState.state === "warning") {
      setLoanpassState({ state: "ready" });
    } else {
      console.warn(
        "Tried to dismiss error or warning but not in error or warning state",
        {
          loanpassState,
        },
      );
    }
  }, [loanpassState]);

  return (
    <>
      {loanpassState.state !== "ready" ? (
        <OverlayScreen
          state={loanpassState}
          onDismissError={onDismissError}
          encompassHost={encompassHost}
        />
      ) : null}
      <iframe
        src={`${appUrl}/frame-redirect/${clientAccessId}`}
        ref={iframeRef}
        onLoad={onIframeLoad}
        title="LoanPASS Pricing"
      />
    </>
  );
};

interface OverlayScreenProps {
  state: LoanpassState;
  onDismissError?: () => void;
  encompassHost: EncompassHost | null;
}

export const OverlayScreen: React.FC<OverlayScreenProps> = (props) => {
  const { state, onDismissError, encompassHost } = props;

  const onDismissErrorClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        e.stopPropagation();
        e.preventDefault();
        onDismissError?.();
      },
      [onDismissError],
    );

  switch (state.state) {
    case "error":
    case "warning":
      return (
        <div className="overlay">
          <div className={`dialog problem-dialog ${state.state}`}>
            {state.state === "error" ? (
              <div className="dialog-title">Error</div>
            ) : (
              <div className="dialog-title">Warning</div>
            )}
            <div className="dialog-content">
              {state.message}
              {state.details.length > 0 && (
                <ul>
                  {state.details.map((detail, index) => (
                    <li key={index}>
                      {detail.key === null ? (
                        detail.detail
                      ) : (
                        <>
                          <span style={{ fontWeight: "bold" }}>
                            {detail.key}
                          </span>
                          <div
                            style={{
                              fontFamily: "monospace",
                              backgroundColor: "#eee",
                              padding: "5px 10px",
                              margin: "10px 0px 15px 0px",
                              color: "#c00",
                            }}
                          >
                            {detail.detail}
                          </div>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {state.state === "warning" || state.dismissable ? (
              <button
                className="button"
                style={{ float: "right" }}
                type="button"
                onClick={onDismissErrorClick}
              >
                Dismiss
              </button>
            ) : null}
          </div>
        </div>
      );
    case "lock-success":
      return (
        <div className="overlay">
          <div className="dialog success-dialog">
            <div className="dialog-title">LoanPASS order complete</div>
            <div className="dialog-content">
              The loan was updated successfully. Close this dialog to return to
              Encompass.
            </div>
            <button
              className="button"
              style={{ float: "right" }}
              type="button"
              onClick={() => {
                if (encompassHost !== null) {
                  encompassHost.transaction.close();
                }
              }}
            >
              Close
            </button>
          </div>
        </div>
      );
  }

  return (
    <div className="overlay">
      <p>Loading...</p>
    </div>
  );
};

type LoanpassIFrameMessage =
  | {
      message: "listening";
    }
  | LoanpassIFrameLockLedgerUpdatedMessage
  | LoanpassIFrameLockLedgerUpdateErrorMessage
  | LoanpassEncompassFloatRequestMessage
  | LoanpassIFramePriceLockMessage
  | LoanpassIFrameVendorContextRequestMessage;

interface LoanpassIFrameLockLedgerUpdatedMessage {
  message: "lock-ledger-updated";
  pipelineRecord: T.PipelineRecordHeader;
  lockLedgerEntries: T.LockLedgerEntry[];
}

interface LoanpassIFrameLockLedgerUpdateErrorMessage {
  message: "lock-ledger-update-error";
  error: string;
}

interface LoanpassIFrameVendorContextRequestMessage {
  message: "vendor-context-request";
  id: string;
  scope: "lock-ledger-entry";
}

interface LoanpassIFramePriceLockMessage {
  message: "price-lock" | "float-request";
  executionRequest: unknown;
  productExecutionResult: unknown;
  scenario: {
    id: string;
  };
}

interface LoanpassEncompassFloatRequestMessage {
  message: "encompass-float-request";
  pipelineRecordId: T.PipelineRecordId;
  pipelineRecordScenarioId: T.PipelineRecordScenarioId;
}
